import { Layout } from '../../components/Layout';
import React, { FC, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { getEnv } from '../../config/env';
import { CrossButton } from '../../components/CrossButton';
import { routes } from '../../domain/routes';
import { useHistory } from '../navigation/router';
import { saveIssuer } from '../auth/authStore';
import { ISSUER_SYS_NAME } from '../../domain/specs/storageKeys';
import { toastr } from 'react-redux-toastr';

export const SetIssuerPage: FC = () => {
  const [issuerName, setIssuerName] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    const sysName = sessionStorage.getItem(ISSUER_SYS_NAME);
    if (sysName) {
      setIssuerName(sysName);
      return;
    }
    setIssuerName(getEnv().ISSUER_SYS_NAME);
  }, []);

  const onCrossClick = () => {
    history.push(routes.dev);
  };

  return (
    <Layout>
      <CrossButton onClick={onCrossClick} />
      <div className="l-row">
        <div className="c-title">Issuer Name</div>

        <div className="c-input">
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              value={issuerName}
              onBlur={(e) => setIssuerName(e.target.value)}
            />
            <span className="c-input__title">Issuer Name</span>
          </label>
        </div>

        <Button
          onClick={() => {
            saveIssuer(issuerName);
            toastr.success('Done', 'Issuer Name set');
          }}
        >
          Save Issuer Name
        </Button>
      </div>
    </Layout>
  );
};
