import React, { FC } from 'react';
import PhoneInputComponent, { PhoneInputProps } from 'react-phone-number-input';
import './phone-styles.css';
import { useTranslation } from 'react-i18next';

export const PhoneInput: FC<PhoneInputProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common.phoneInput' });

  return (
    <div className="c-phone">
      <PhoneInputComponent
        placeholder={t('placeholder') ?? ''}
        inputClassName="c-phone__input"
        defaultCountry="UA"
        flagUrl="https://catamphetamine.gitlab.io/country-flag-icons/3x2/{XX}.svg"
        international
        {...props}
      />
    </div>
  );
};
