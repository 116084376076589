import React, { FC } from 'react';
import bwipjs from 'bwip-js';

export type BarcodeType = 'qrcode';

const makeBarcode =
  (barcodeMessage: string, barcodeFormat: BarcodeType) => (el: HTMLCanvasElement) => {
    bwipjs.toCanvas(el, {
      bcid: barcodeFormat,
      text: barcodeMessage,
      width: 300,
      height: 300,
    });
  };

export const Barcode: FC<{ message: string }> = ({ message }) => {
  const make = makeBarcode(message, 'qrcode');

  return (
    <canvas
      style={{ width: '100%', maxWidth: 700, padding: 40 }}
      ref={(el) => {
        el && make(el);
      }}
    ></canvas>
  );
};
