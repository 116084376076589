import { isEmpty } from 'lodash';
import { activationMode } from '../../config/signupConfig';

export const isEmailValid = (email: string): boolean => {
  if (isEmpty(email)) return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const isNameValid = (name: string): boolean => {
  if (isEmpty(name)) return false;
  const re = /^[A-z\s\u0400-\u04FF\u002D\u02BC\u2018\u2019\u0027\u0060]+$/;
  return re.test(name);
};

export const obfuscateEmail = (emailString: string) => {
  if (!emailString) return '';
  const ASTERISK_COUNT = 3;
  const splitEmail = emailString.split('@');
  const [domain, name] = [splitEmail[1], splitEmail[0]];
  if (!domain || !name) return '';
  const obfuscatedName = `${name.substring(0, ASTERISK_COUNT)}${Array(
    name.length - (ASTERISK_COUNT - 1),
  ).join('*')}`;
  return `${obfuscatedName}@${domain}`;
};

export const isActivationModeBasic = () => activationMode === 'basic';
export const isActivationModeStandard = () => activationMode === 'standard';

export const isUserAndroid = () => getMobileOperatingSystem() === 'Android';
export const isUserIos = () => getMobileOperatingSystem() === 'iOS';

const getMobileOperatingSystem = () => {
  //@ts-ignore
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // Opera specific key MSStream
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
};
