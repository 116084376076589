import React, { FC, useCallback, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { useAppDispatch } from '../store/hooks';
import { setPhone } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { PhoneInput } from '../../components/PhoneInput';
import { phoneNumberMaxLength } from '../../config/appConfig';
import { useTranslation } from 'react-i18next';

const SEND_CODE_DELAY = 30;
const PHONE_MIN_LENGTH = 5;
export const SetPhonePage: FC = () => {
  const dispatch = useAppDispatch();
  useRedirectOnAuthStepChangeEffct();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [delay, setDelay] = useState(0);
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.setPhone' });

  const countdown = useCallback(() => {
    setDelay(delay - 1);
  }, [delay]);

  useEffect(() => {
    if (delay > 0) {
      window.setTimeout(countdown, 1000);
    }
  }, [delay, countdown]);

  const canSendCode =
    !isEmpty(phoneNumber) && delay === 0 && phoneNumber.length >= PHONE_MIN_LENGTH;
  const sendCode = () => {
    setDelay(SEND_CODE_DELAY);
    dispatch(setPhone(phoneNumber));
  };

  const countdownTitle = delay > 0 ? delay : '';
  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendCode} isDisabled={!canSendCode}>
            {t('buttonLabel', { countdownTitle })}
          </ButtonWithLoading>
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">{t('header')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">{t('text')}</div>
      </div>
      <div className="l-row">
        <PhoneInput
          maxLength={phoneNumberMaxLength}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </div>
    </Layout>
  );
};
