import { SignupStep } from '../../domain/entities/signup';
import { routes } from '../../domain/routes';

export const signupPageByState: Record<SignupStep, string> = {
  [SignupStep.Basic]: routes.signupTerms,
  [SignupStep.Profile]: routes.signupProfile,
  [SignupStep.SetPhone]: routes.signupSetPhone,
  [SignupStep.PhoneActivation]: routes.signupPhoneActivation,
  [SignupStep.PhoneActivationSuccess]: routes.signupPhoneActivationSuccess,
  [SignupStep.SetEmail]: routes.signupSetEmail,
  [SignupStep.EmailActivation]: routes.signupCheckInbox,
  [SignupStep.Completed]: routes.profile,
};

export const signupStepsLabels = {
  [SignupStep.Basic]: "",
  [SignupStep.Profile]: "Set Profile",
  [SignupStep.SetPhone]: "",
  [SignupStep.PhoneActivation]: "",
  [SignupStep.PhoneActivationSuccess]: "",
  [SignupStep.SetEmail]: "Verify Your Email",
  [SignupStep.EmailActivation]: "",
  [SignupStep.Completed]: "Profile",
}
