import React, { FC, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { useAppDispatch } from '../store/hooks';
import { setProfile, skipProfile } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { useParams } from '../navigation/router';
import { isNameValid } from '../user/utils';
import { DEMO_PROFILE_CONTINUE_CLICK } from '../../domain/specs/analyticsActionCodes';
import { IMessageBus } from '@roketus/web-toolkit';
import { getDependency } from '../diContext';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';

export const SetupProfilePage: FC = () => {
  const activation = useSelector(getActivation);
  const dispatch = useAppDispatch();
  const dispatchWithHistory = useDispatchWithHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.setProfile' });

  const params = useParams<{ userId?: string }>();
  useRedirectOnAuthStepChangeEffct();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });

  let userId = params.userId;

  const canSetProfile = !isEmpty(firstName) && !isEmpty(lastName);
  const messageBus = getDependency('messageBus') as IMessageBus;

  const next = () => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_PROFILE_CONTINUE_CLICK,
      source: 'ProfilePage',
    });

    dispatchWithHistory(setProfile, {
      firstName,
      lastName,
      userId,
    });
  };

  const handleOnSkipProfileCLick = useCallback(() => {
    dispatch(skipProfile());
  }, [dispatch]);

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={next} isDisabled={!canSetProfile}>
            {t('continueButton')}
          </ButtonWithLoading>
          {activation && activation.nextStepSkippable && (
            <div className="l-row l-row--mt-16">
              <button className="c-link" onClick={handleOnSkipProfileCLick}>
                {t('skipButton')}
              </button>
            </div>
          )}
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">{t('header')}</div>
      </div>
      <div className="l-row">
        <Input
          value={firstName}
          onChange={setFirstName}
          validateFn={isNameValid}
          label={t('firstNameLabel') as string}
          validationMessage={tError('firstNameError') as string}
        />
      </div>
      <div className="l-row l-row--mt-16">
        <Input
          value={lastName}
          onChange={setLastName}
          validateFn={isNameValid}
          label={t('lastNameLabel') as string}
          validationMessage={tError('lastNameError') as string}
        />
      </div>
      <div className="l-row">{t('cardPreview')}</div>
      <div className="l-row l-row--mt-16">
        <div className="c-card">
          <div className="c-card__name">
            {firstName} {lastName}
          </div>
          <div className="c-card__number">1234 - 1234 - 1234</div>
        </div>
      </div>
    </Layout>
  );
};
