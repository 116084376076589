import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { routes } from '../../domain/routes';
import { RouteProps } from 'react-router';

export const RouteProfileActivation: FC<RouteProps> = ({
  children,
  ...rest
}) => {
  const activation = useSelector(getActivation);
  const isSignRequiredStepsComplete = activation?.nextStepSkippable || activation?.completed;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isSignRequiredStepsComplete ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.profile,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
