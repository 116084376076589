const getIssuerSysName = (): string => {
  try {
    const issuerSysName = process.env.REACT_APP_DEFAULT_ISSUER;

    if (issuerSysName) return issuerSysName;
  } catch (e) {
    console.log(e);
  }

  const fallback = 'unknown';

  console.log(`Can't get issuer, fallback to default ${fallback}`);
  return fallback;
};

export const getEnv = () => ({
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_PUSH_SERVER_PUBLIC_KEY: process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY,
  ISSUER_SYS_NAME: getIssuerSysName(),
});
