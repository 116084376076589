import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get, isNull } from 'lodash';
import { persistReducer } from 'redux-persist';

import { getUserDataAPI, signInByPhoneAPI, validatePhoneOtpAPI } from '../api/auth';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { finishRequest, startRequest } from '../global/globalStore';
import {
  AuthByJWTActionPayload,
  SetTokenActionPayload,
  SignInByPhoneActionPayload,
  ValidatePhoneOtpActionPayload,
} from '../../boundries/actionsPayloads/authActionPayloads';
import { generatePath } from '../navigation/router';
import { routes } from '../../domain/routes';
import { ActionWithHistory, createActionWithHistory } from '../actionWithHistory';
import storage from 'redux-persist/lib/storage';
import { AuthState } from '../../boundries/storeState/AuthState';
import { getLogin } from '../dbStore/dbStore';
import { LoginEntity } from '../../domain/entities/auth';
import { RootState } from '../../boundries/storeState/RootState';
import { handleError } from '../error/utils';
import { toastr } from 'react-redux-toastr';
import { unSubscribeFromPushManager } from '../subscriptionNotifications';
import { IHTTPClientResponse } from '@roketus/web-toolkit';
import { changeProfileAPI } from '../api/user';
import { ISSUER_SYS_NAME, JWT_AUTH } from '../../domain/specs/storageKeys';
import { getDependency } from '../diContext';
import { IUserAnalyticsService } from '../../boundries/IUserAnalyticsService';

const STORE_KEY = 'auth';

const persistConfig = {
  key: STORE_KEY,
  storage,
};

const initialState: AuthState = {
  token: null,
};
const authSlice = createSlice({
  name: STORE_KEY,
  initialState,
  reducers: {
    setTokenValue: (state, action: PayloadAction<string>) => {
      console.log('new token', action.payload, state);
      state.token = action.payload;
      console.log('new state', action.payload, state);
    },
  },
});

export const { setTokenValue } = authSlice.actions;
const authSimpleReducer = authSlice.reducer;
export const authReducer = persistReducer(persistConfig, authSimpleReducer);

export const signIn = createActionWithHistory<SignInByPhoneActionPayload>('auth/signIn');
export const validatePhoneOtp =
  createActionWithHistory<ValidatePhoneOtpActionPayload>('auth/validatePhoneOtp');
export const updateUserData = createAction('auth/updateUserData');
export const setToken = createAction<SetTokenActionPayload>('auth/setToken');
export const authByJWT = createActionWithHistory<AuthByJWTActionPayload>('auth/authByJWT');
export const logout = createAction('auth/logout');

const getStore = (store: RootState) => store[STORE_KEY];
export const getToken = createSelector(getStore, (state) => state.token);
export const getIsAuthenticated = createSelector(getToken, (token) => !isNull(token));

function* signInSaga(action: PayloadAction<ActionWithHistory<SignInByPhoneActionPayload>>) {
  try {
    const {
      data: { phone },
      history,
    } = action.payload;
    yield put(startRequest());
    yield call(signInByPhoneAPI, phone);

    const path = generatePath(routes.signInvalidatePhoneOtp, {
      phoneNumber: phone,
    });
    yield call(history.push, path);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* authByJWTSaga(action: PayloadAction<ActionWithHistory<AuthByJWTActionPayload>>) {
  try {
    const {
      data: { jwt, issuer, gauid },
      history,
    } = action.payload;
    console.log('Starting automatic signin', jwt);
    yield put(startRequest());
    yield call(setTokenSaga, { payload: jwt, type: 'synt' });
    yield call(saveIssuer, issuer);
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      changeProfileAPI,
      'Demo',
      'User',
    );
    localStorage.setItem(JWT_AUTH, 'ENABLED');
    const userAnalyticsService: IUserAnalyticsService = yield call(
      getDependency,
      'userAnalyticsService',
    );
    yield call(userAnalyticsService.setUserID, gauid);

    yield call<saveJsonApiResponseToDbFunction>(saveJsonApiResponseToDb, response.data);
    const path = generatePath(routes.profile);
    yield call(history.push, path);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* validatePhoneSaga(
  action: PayloadAction<ActionWithHistory<ValidatePhoneOtpActionPayload>>,
) {
  try {
    const {
      data: { phone, otp },
      history,
    } = action.payload;
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      validatePhoneOtpAPI,
      phone,
      parseInt(otp, 10),
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    const login: LoginEntity | undefined = yield select(getLogin);

    if (!login) throw Error(`login not found`);
    yield call(setTokenSaga, { payload: login.jwt, type: 'synt' });

    yield call(history.push, routes.profile);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* updateUserDataSaga() {
  try {
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(getUserDataAPI);

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    const status = get(e, 'response.status');
    if (status === 422) {
      yield put(logout());
    }

    yield call(handleError, e as Error);
  }
}

function* setTokenSaga(action: PayloadAction<SetTokenActionPayload>) {
  try {
    const jwt = action.payload;
    yield put(setTokenValue(jwt));
  } catch (e) {
    yield call(handleError, e as Error);
  }
}

function* logoutSaga() {
  try {
    // @ts-ignore
    yield call(setTokenSaga, { payload: null });
    localStorage.removeItem(ISSUER_SYS_NAME);
    localStorage.removeItem(JWT_AUTH);
    yield call(unSubscribeFromPushManager);

    yield call(toastr.success, 'Done', 'Session cleared');
  } catch (e) {
    yield call(handleError, e as Error);
  }
}

export function* authSaga() {
  yield takeLatest(signIn, signInSaga);
  yield takeLatest(authByJWT, authByJWTSaga);
  yield takeLatest(validatePhoneOtp, validatePhoneSaga);
  yield takeLatest(updateUserData, updateUserDataSaga);
  yield takeLatest(setToken, setTokenSaga);
  yield takeLatest(logout, logoutSaga);
}

export const saveIssuer = (issuerName: string) => {
  localStorage.setItem(ISSUER_SYS_NAME, issuerName);
  unSubscribeFromPushManager();
};
