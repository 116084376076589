import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { routes } from '../../domain/routes';
import { RouteProps } from 'react-router';
import { getIsAuthenticated } from '../auth/authStore';

export const RouteAuthorized: FC<RouteProps> = ({ children, ...rest }) => {
  const isAuth = useSelector(getIsAuthenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.signIn,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
