import React, { FC, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { CrossButton } from '../../components/CrossButton';
import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { getProfile } from '../dbStore/dbStore';
import { changeProfile } from './userSaga';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { isNameValid } from './utils';

export const ChangeProfilePage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const history = useHistory();
  const profile = useSelector(getProfile);
  const [firstName, setFirstName] = useState(!isUndefined(profile) ? profile.firstName : '');
  const [lastName, setLastName] = useState(!isUndefined(profile) ? profile.lastName : '');
  const { t } = useTranslation('translation', { keyPrefix: 'changeProfile' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });

  const canUpdate = !isEmpty(firstName) && !isEmpty(lastName);
  const updateName = () => {
    dispatch(changeProfile, {
      firstName,
      lastName,
    });
  };

  const handleBack = () => {
    history.push(routes.profile);
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={updateName} isDisabled={!canUpdate}>
            {t('buttonLabel')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />
      <div className="l-row">
        <div className="c-title">{t('title')}</div>
      </div>
      <div className="l-row">
        <Input
          value={firstName}
          onChange={setFirstName}
          validateFn={isNameValid}
          label={t('firstNameLabel') as string}
          validationMessage={tError('firstNameError') as string}
        />
      </div>
      <div className="l-row">
        <Input
          value={lastName}
          onChange={setLastName}
          validateFn={isNameValid}
          label={t('lastNameLabel') as string}
          validationMessage={tError('lastNameError') as string}
        />
      </div>
    </Layout>
  );
};
