import { getCtx } from '../diContext';
import { getEnv } from '../../config/env';
export const createCardAPI = () => createCardByReferralAPI('id');

export const createCardByReferralAPI = (referral_id: string) => {
  const { sdk } = getCtx();

  return sdk.user.card.downloadAnonymousCard(getEnv().ISSUER_SYS_NAME, referral_id);
};

export const getUserCardAPI = () => {
  const { sdk } = getCtx();

  return sdk.user.card.getUserCard();
};

export const downloadIOSCardAPI = () => {
  const { sdk } = getCtx();
  return sdk.user.card.downloadIOSCard();
};

export const downloadAndroidCardAPI = () => {
  const { sdk } = getCtx();
  return sdk.user.card.getAndroidCardDownloadLink();
};

export const getReferralAPI = () => {
  const { sdk } = getCtx();
  return sdk.user.card.getReferral();
};

export const getBalanceHistoryAPI = () => {
  const { sdk } = getCtx();
  return sdk.user.card.getBalanceHistory();
};
