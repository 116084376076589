import { GetPushNotificationDTO } from './notificationsApi';
import { isFirefox } from 'react-device-detect';
import {
  canShowNotifications,
  isNotificationsAccepted,
  isNotificationsRejected,
  isNotificationsSupported,
  isNotificationsViewed,
} from './helpers';

export const isUserActionForPushSubscriptionNeeded = () => isFirefox;

const displayNotification = (
  notification: GetPushNotificationDTO,
  registration: ServiceWorkerRegistration,
) => {
  console.log('notification payload', notification);
  const options = {
    body: notification.body,
    icon: 'favicon.ico',
    vibrate: [200, 100, 200, 100, 200, 100, 400],
  };
  registration?.showNotification(notification.title, options);
};

export const tryDisplayNotification = (
  notification: GetPushNotificationDTO,
  registration: ServiceWorkerRegistration,
): Promise<void | NotificationPermission> => {
  if (!isNotificationsSupported() || !isNotificationsViewed()) {
    return Promise.reject();
  }

  if (isNotificationsAccepted()) {
    displayNotification(notification, registration);
    return Promise.resolve();
  }

  return Notification.requestPermission(() => {
    if (isNotificationsAccepted()) {
      displayNotification(notification, registration);
    } else {
      console.log('Notifications denied');
    }
  });
};

// @TODO add remote logging
const requestNotificationPermission = () => {
  if (!isNotificationsSupported()) {
    console.error('Notifications are not supported');
  }

  if (isNotificationsAccepted()) {
    return;
  }

  if (isNotificationsRejected()) {
    console.log('User denied Notifications');
    // Some cta can be here or just logging
  }

  Notification.requestPermission().then((permission) => {
    // If the user accepts, let's create a notification
    if (canShowNotifications(permission)) {
      console.log('Successfully granted permission for notifications');
    }
  });
};

export const initNotifications = () => {
  requestNotificationPermission();

  setInterval(requestNotificationPermission, 3000);
};
