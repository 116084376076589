import React, { FC, useState } from 'react';

import { Layout } from '../../components/Layout';
import { useParams } from '../navigation/router';
import { useAppDispatch } from '../store/hooks';
import { acceptTermsByUser } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { titles } from '../../config/titles';
import { Trans, useTranslation } from 'react-i18next';

const LINK_TO_TERMS = '/agreement.pdf';
export const TermsPage: FC = () => {
  const { referralId } = useParams<{ referralId?: string | undefined }>();
  const dispatch = useAppDispatch();
  useRedirectOnAuthStepChangeEffct();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.acceptTerms' });

  const acceptFn = () => {
    dispatch(acceptTermsByUser({ referralId }));
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={acceptFn} isDisabled={!termsAccepted}>
            {t('buttonLabel')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">{t('header', { issuerTitle: titles.issuerTitle })}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          <Trans
            i18nKey="text" // optional -> fallbacks to defaults if not provided
            t={t}
            values={{ issuerTitle: titles.issuerTitle }}
            components={{ italic: <i />, under: <u /> }}
          />
        </div>
      </div>
      <div className="l-row l-row--mt-80">
        <div className="c-checkbox">
          <label className="c-checkbox__label">
            <input
              type="checkbox"
              name="checkbox"
              hidden
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <span className="c-checkbox__icon" />
            <span className="c-checkbox__title">
              <Trans
                i18nKey="checkboxText" // optional -> fallbacks to defaults if not provided
                t={t}
                values={{ issuerTitle: titles.issuerTitle }}
                components={{
                  ln: (
                    <a
                      href={LINK_TO_TERMS}
                      target="_blank"
                      className="c-checkbox__link"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
