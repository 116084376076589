import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { isUndefined } from 'lodash';

interface IProps {
  onChange: (value: string) => void;
  value: string | undefined | number;
  placeholder?: string;
  label?: string;
  validateFn?: (value: string) => boolean;
  validationMessage?: string;
}
export const Input: FC<IProps> = ({
  onChange,
  value,
  placeholder,
  validateFn,
  validationMessage,
  label,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const onChangeCb = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (isFocused) {
      setIsDirty(true);
    }
    onChange(newValue);
  };

  const isValid = useMemo(() => {
    if (!isDirty) {
      return true;
    }

    if (isUndefined(validateFn)) {
      return true;
    }
    return validateFn(value as string);
  }, [isDirty, validateFn, value]);
  return (
    <div className={cn('c-input', !isValid && 'c-input--validation')}>
      <div className="c-input__message c-input__message--error">{validationMessage}</div>
      <label className="c-input__label">
        <input
          className="c-input__type"
          type="text"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onClick={() => setIsFocused(true)}
          placeholder={placeholder}
          value={value}
          onChange={onChangeCb}
        />
        <span className="c-input__title">{label}</span>
      </label>
    </div>
  );
};
