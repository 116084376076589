import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Navigation } from './interface-adapters/navigation/Navigation';
import { Router } from './interface-adapters/navigation/router';
import { persistor, store } from './interface-adapters/store/store';
import ReduxToastr from 'react-redux-toastr';
import { PersistGate } from 'redux-persist/integration/react';

import './App.css';
import './scss/_style.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { DIBootstrap } from './interface-adapters/global/DIBootstrap';
import { LogUnhandledErrorsService } from './interface-adapters/error/LogUnhandledErrorsService';
import { LoadingPage } from './components/pages/LoadingPage/LoadingPage';

function App() {
  const [useLoadingScreen, setUseLoadingScreen] = useState(true);

  useEffect(() => {
    setTimeout(() => setUseLoadingScreen(false), 1500);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DIBootstrap>
          <LogUnhandledErrorsService>
            {useLoadingScreen ? (
              <LoadingPage />
            ) : (
              <Router>
                <Navigation />
              </Router>
            )}

            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-left"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </LogUnhandledErrorsService>
        </DIBootstrap>
      </PersistGate>
    </Provider>
  );
}

export default App;
