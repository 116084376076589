import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { routes } from '../../domain/routes';
import { DevListPage } from '../../DevListPage';
import { SetIssuerPage } from '../user/SetIssuerPage';
import { ActivationUser } from '../user/ActivationUser';
import { SetupProfilePage } from '../signup/SetupProfilePage';
import { TermsPage as SignupTermsPage } from '../signup/TermsPage';
import { DownloadCardPage } from '../card/DownloadCardPage';
import { DownloadCardByReferralPage } from '../card/DownloadCardByReferralPage';
import { SignInPage } from '../auth/SignInPage';
import { ValidateOtpPage } from '../auth/ValidateOtpPage';
import { SignInByJWT } from '../auth/SignInByJWT';
import { ProfilePage as Profile } from '../user/ProfilePage';
import { ChangePhonePage } from '../user/ChangePhonePage';
import { ChangePhoneActivationPage } from '../user/ChangePhoneActivationPage';
import { ChangeEmailPage } from '../user/ChangeEmailPage';
import { ChangeProfilePage } from '../user/ChangeProfilePage';
import { InviteFriendPage } from '../user/InviteFriendPage';
import { RouteProfileActivation } from './RouteProfileActivation';
import { SetPhonePage } from '../signup/SetPhonePage';
import { PhoneActivationPage } from '../signup/PhoneActivationPage';
import { EmailVerificationPage } from '../signup/EmailVerificationPage';
import { CheckInboxPage } from '../signup/CheckInboxPage';
import { EmailVerificationSuccessPage } from '../signup/EmailVerificationSuccessPage';
import { EmailVerificationErrorPage } from '../signup/EmailVerificationErrorPage';
import { RouteAuthorized } from './RouteAuthorized';

export const Navigation = () => {
  return (
    <Switch>
      {/* Public pages */}
      <Route path={routes.dev} exact>
        <DevListPage />
      </Route>
      <Route path={routes.setIssuer}>
        <SetIssuerPage />
      </Route>
      <Route path={routes.activationUser}>
        <ActivationUser />
      </Route>
      <Route path={routes.signupProfile}>
        <SetupProfilePage />
      </Route>
      <Route path={routes.signupTermsByReferral}>
        <SignupTermsPage />
      </Route>
      <Route path={routes.signupTerms}>
        <SignupTermsPage />
      </Route>
      <Route path={routes.downloadCard}>
        <DownloadCardPage />
      </Route>
      <Route path={routes.downloadCardByReferral}>
        <DownloadCardByReferralPage />
      </Route>
      <Route path={routes.signIn} exact>
        <SignInPage />
      </Route>
      <Route path={routes.signInvalidatePhoneOtp}>
        <ValidateOtpPage />
      </Route>
      <Route path={routes.signInJWT}>
        <SignInByJWT />
      </Route>

      {/* Activation flow pages */}

      <RouteProfileActivation path={routes.signupSetPhone}>
        <SetPhonePage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupPhoneActivation}>
        <PhoneActivationPage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupPhoneActivationSuccess}>
        <SetupProfilePage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupSetEmail}>
        <EmailVerificationPage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupCheckInbox}>
        <CheckInboxPage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupVerifyEmailSuccess}>
        <EmailVerificationSuccessPage />
      </RouteProfileActivation>
      <RouteProfileActivation path={routes.signupVerifyEmailError}>
        <EmailVerificationErrorPage />
      </RouteProfileActivation>

      {/* Auth pages */}

      <RouteAuthorized path={routes.profile} exact>
        <Profile />
      </RouteAuthorized>
      <RouteAuthorized path={routes.profileChangePhone}>
        <ChangePhonePage />
      </RouteAuthorized>
      <RouteAuthorized path={routes.profileChangePhoneActivation}>
        <ChangePhoneActivationPage />
      </RouteAuthorized>
      <RouteAuthorized path={routes.profileChangeEmail}>
        <ChangeEmailPage />
      </RouteAuthorized>
      <RouteAuthorized path={routes.profileChangeName}>
        <ChangeProfilePage />
      </RouteAuthorized>
      <RouteAuthorized path={routes.profileInviteFriend}>
        <InviteFriendPage />
      </RouteAuthorized>

      {/* Default page fallback */}

      <RouteAuthorized path="*">
        <Profile />
      </RouteAuthorized>
    </Switch>
  );
};
