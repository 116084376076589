import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from '../interface-adapters/navigation/router';
import { routes } from '../domain/routes';
import cn from 'classnames';

interface Props {
  footer?: ReactNode;
  fullWidth?: boolean;
}

let timer: ReturnType<typeof setTimeout>;

const useDevModeEffct = () => {
  const [clicks, setClicks] = useState(0);
  const history = useHistory();

  const handler = () => {
    setClicks(clicks + 1);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setClicks(0);
    }, 1000);
  };

  useEffect(() => {
    if (clicks > 10) history.push(routes.dev);
  }, [clicks, history]);

  return handler;
};
export const Layout: FC<Props> = ({ children, footer, fullWidth = false }) => {
  return (
    <div className={cn('l-page', fullWidth && 'l-page--fw')}>
      <main className="l-main">{children}</main>
      {footer}
    </div>
  );
};
