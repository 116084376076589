import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      common: {
        phoneInput: {
          placeholder: 'Enter phone number',
        },
        errors: {
          'user not found': 'User not found',
          'wrong phone code': 'Wrong phone code',
          unknownTitle: 'Error',
          unknownText: 'Oops, something went wrong',
          firstNameError: 'First Name is not valid',
          lastNameError: 'Last Name is not valid',
        },
      },
      signIn: {
        index: {
          header: 'Sign In',
          text: 'Choose Your Country Code and enter your phone number',
          buttonLabel: 'Sign Up',
          confirmationLabel: 'Send Confirmation Code',
        },
        validateOtp: {
          header: 'Please enter your verification code',
          text: 'Enter the 6-digit code we have sent to',
          secondText: 'xxx xxxx {{phoneNumberSlice}}.',
          changePhoneNumber: 'Edit phone number',
          noCode: 'Did not receive verification code?',
          resendCode: 'Resend code',
          waitResend: 'Wait 30 seconds to try again',
          buttonLabel: 'Verify code',
        },
      },

      signUp: {
        acceptTerms: {
          header: 'Benefit with {{issuerTitle}} loyalty card',
          secondHeader: 'By clicking “Accept”, you agree to the',
          text: 'Earn 5% each time you shop at {{titles.issuerTitle}}.<br />Get access to deals and offers just for you.',
          checkboxText: 'I agree with <ln>TERMS AND CONDITIONS</ln>',
          buttonLabel: 'Create account',
        },
        setPhone: {
          header: 'Please enter your mobile phone number',
          text: 'We will send you a 6-digit verification code',
          buttonLabel: 'Send Code {{countdownTitle}}',
        },
        setProfile: {
          firstNameError: 'First Name is not valid',
          firstNameLabel: 'First Name',
          lastNameError: 'Last Name is not valid',
          lastNameLabel: 'Last Name',
          continueButton: 'Continue',
          skipButton: 'Skip',
          header: 'Please enter your name',
          cardPreview: 'Card Preview',
        },
      },
      profile: {
        title: 'Your Account',
        subtitle: 'Hello, {{name}}',
        mainText:
          'This is your {{issuerTitle}} loyalty program account where you can check your balance, invite friends and manage your personal information.',
        dateLabel: 'Valid through: ',
        downloadCardForIos: 'Download card for iOS',
        downloadCardForAndroid: 'Download card for Android',
        bonusesLabel: 'Your bonuses: {{balance}}',
        referral: {
          title: '{{issuerTitle}} referral program',
          text: 'Invite your friend and earn 5 bonuses',
          buttonLabel: 'Invite Your Friend',
          copy: 'Copy Url',
          copied: 'Url Copied!',
        },
        updateName: 'Update Name on Card',
        updatePhone: 'Update Phone Number',
        updateEmail: 'Update Email',
        subscribe: 'Do you want to subscribe to notifications?',
        logout: 'Log Out',
      },
      changeProfile: {
        title: 'Update Personal Info',
        buttonLabel: 'Update',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
      },
      changePhoneNumber: {
        title: 'Update your mobile phone number',
        buttonLabel: 'Update',
        updateButtonLabel: 'Update {{time}}',
      },
      system: {
        otp: {
          required: `otp is required`,
          pattern: 'only numbers are allowed',
          minLength: 'Min length 6 symbols',
          maxLength: 'Max length 6 symbols',
        },
      },
    },
  },
  uk: {
    translation: {
      common: {
        phoneInput: {
          placeholder: '',
        },
        errors: {
          'user not found': 'Ой помилка, Юзера не здайдено',
          'wrong phone code': 'Ой помилка, невірний код',
          unknownTitle: 'Помилка',
          unknownText: 'Упс, щось пішло не так!',
          firstNameError: 'Невірний формат',
          lastNameError: 'Невірний формат',
        },
      },
      signIn: {
        index: {
          header: 'Увійти',
          text: 'Номер телефону',
          buttonLabel: 'Приєднатись, якщо ще не з нами',
          confirmationLabel: 'Код підтвердження',
        },
        validateOtp: {
          // header: 'Please enter your verification code',
          text: 'Введи 6 цифр, що надійшли на твій номер телефону',
          secondText: 'xxx xxxx {{phoneNumberSlice}}.',
          changePhoneNumber: 'Ой, змінити номер телефону',
          noCode: 'Не приходить код?',
          resendCode: 'Надіслати код ще раз',
          waitResend: 'Зачекай 30 секунд, щоб спробувати знову',
          buttonLabel: 'Підтвердити',
        },
      },

      signUp: {
        acceptTerms: {
          header: 'ТВОЯ КАВО-КАРТА',
          secondHeader: 'Дає можливість:',
          text: 'Дає можливість: <under>обрати будь-який напій</under> (еспресо, фільтр, капучино чи лате на звичайному молоці) безкоштовно* за кожну 5 випиту чашку.  Мається на увазі покупка за 0,01 грн.',
          checkboxText: 'Я погоджуюсь з <ln>умовами користування Каво-картою</ln>',
          buttonLabel: 'Створити аккаунт',
        },
        setPhone: {
          header: 'Встанови номер телефону',
          text: '',
          buttonLabel: 'Підтвердити',
        },
        setProfile: {
          firstNameLabel: 'Ім’я',
          lastNameLabel: 'Прізвище',
          continueButton: 'Підтвердити',
          skipButton: '',
          header: 'Надайте інформацію про себе',
          cardPreview: 'Попередній вигляд карти',
        },
      },
      profile: {
        // title: '',
        subtitle: 'Привіт, {{name}}',
        mainText:
          'Ти в клубі тістувальників і певно любиш не тільки тістечка, але і каву. Відтепер ми тебе пригощаємо кожною 6 чашкою кави. Бунь з нами ',
        dateLabel: 'Дійсна до: ',
        downloadCardForIos: 'iOS',
        downloadCardForAndroid: 'Android',
        bonusesLabel: 'Твоя карта',
        referral: {
          title: 'ТВОЯ КАВО-КАРТА',
          text: 'Дає можливість <u>обрати будь-який напій</u> (серед таких як еспресо, фільтр, капучино чи лате на звичайному молоці) безкоштовно* за кожну 5 випиту чашку.  Мається на увазі покупка за 0,01 грн.',
          buttonLabel: 'Бунь з нами',
          // copy: '',
          // copied: '',
        },
        updateName: 'Змінити ім’я на картці',
        updatePhone: 'Змінити номер телефону',
        updateEmail: 'Змінити мейл',
        subscribe: 'Хочете підписатись на повідомлення?',
        logout: 'Вийти',
      },
      changePhoneNumber: {
        title: 'Змінити номер телефону',
        buttonLabel: 'Підтвердити',
        updateButtonLabel: 'Підтвердити через {{time}}',
      },
      changeProfile: {
        title: 'Змінити інформацію Про себе',
        buttonLabel: 'Підтвердити',
        firstNameLabel: 'Ім’я',
        lastNameLabel: 'Прізвище',
      },
      system: {
        otp: {
          required: `Обов'язкове поле`,
          pattern: 'Невірний формат',
          minLength: 'Мінімальна довжина 6 символів',
          maxLength: 'Максимальна довжина 6 символів',
        },
      },
    },
  },
};

export const initI18n = () =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: 'uk', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      debug: true,
      fallbackLng: 'en',
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u'],
      },
    });

export { i18n };
