import React from 'react';
import { useSelector } from 'react-redux';

import './App.css';
import { Link } from './interface-adapters/navigation/router';
import { getUserId } from './interface-adapters/dbStore/dbStore';
import { routes } from './domain/routes';
import { useAppDispatch } from './interface-adapters/store/hooks';
import { logout } from './interface-adapters/auth/authStore';
import { sendPush } from './interface-adapters/user/userSaga';
import {
  subscribeToPushManager,
  unSubscribeFromPushManager,
} from './interface-adapters/subscriptionNotifications';
import { toastr } from 'react-redux-toastr';

export const DevListPage = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector(getUserId);

  const onSendPush = () => {
    if (userId) {
      dispatch(
        sendPush({
          userId,
          title: 'Push title',
          message: 'Push BODY',
        }),
      );
    }
  };

  const onSubscribeNotifications = async () => {
    const result = await subscribeToPushManager();
    toastr.info('Subscription', result + '');
  };

  const onUnsubscribeNotifications = async () => {
    const result = await unSubscribeFromPushManager();
    toastr.info('Unsubscribe', result);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <h1>Developers Mode, Warning, Here be dragons!</h1>
      <ol style={{ textAlign: 'left', lineHeight: '2.5' }}>
        <li>
          <Link to={routes.signIn}>Log In</Link>
        </li>
        <li>
          <Link to={routes.downloadCard}>Loyalty Program</Link>
        </li>
        <li>
          <Link to={routes.profile}>Your Account</Link>
        </li>
        <li>
          <Link to={routes.downloadCard}>Download Card</Link>
        </li>
        <li>
          <Link to={routes.downloadCardByReferral}>Download Card by Ref</Link>
        </li>
        <li>
          <Link to={routes.signupTerms}>Start signup</Link>
        </li>
        <li>
          <a href="#!">
            <span onClick={onSubscribeNotifications}>Subscribe Notifications</span>
          </a>
        </li>
        <li>
          <a href="#!">
            <span onClick={onUnsubscribeNotifications}>Unsubscribe Push</span>
          </a>
        </li>
        <li>
          <a href="#!">
            <span style={{ cursor: 'pointer' }} onClick={() => onSendPush()}>
              Send push notification
            </span>
          </a>
        </li>
        <li>
          <Link to={routes.setIssuer}>Set Issuer</Link>
        </li>
        <li>
          <span style={{ cursor: 'pointer' }} onClick={() => dispatch(logout())}>
            Logout
          </span>
        </li>
      </ol>
      <h2>Build info</h2>
      <dd>
        <b>Version:</b> {process.env.REACT_APP_VERSION}
      </dd>
      <dd>
        <b>App name:</b> {process.env.REACT_APP_NAME}
      </dd>
      <dd>
        <b>REACT_APP_API_URL:</b> {process.env.REACT_APP_API_URL}
      </dd>
      <dd>
        <b>REACT_APP_URL:</b> {process.env.REACT_APP_URL}
      </dd>
      <dd>
        <b>REACT_APP_DEFAULT_ISSUER:</b> {process.env.REACT_APP_DEFAULT_ISSUER}
      </dd>
      <dd>
        <b>REACT_APP_GTA3_KEY:</b> {process.env.REACT_APP_GTA3_KEY}
      </dd>
      <dd>
        <b>REACT_APP_GTA4_KEY:</b> {process.env.REACT_APP_GTA4_KEY}
      </dd>
      <dd>
        <b>REACT_APP_ENVIRONMENT:</b> {process.env.REACT_APP_ENVIRONMENT}
      </dd>
      <dd>
        <b>REACT_APP_PUSH_SERVER_PUBLIC_KEY:</b> {process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY}
      </dd>
    </div>
  );
};
